<template>
  <div class="row">
    <div class="col-12">
      
      <div class="accordion" if="filterAccordion">
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingOne">
            <button
              class="accordion-button bold"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#filters"
              aria-expanded="false"
              aria-controls="filters"
            >
              {{ $t('events.filters.title') }} ({{ $t('events.filters.click_to_open') }})
            </button>
          </h2>
          <!-- Filter  -->
          <div
            id="filters"
            class="accordion-collapse collapse show"
            aria-labelledby="headingOne"
          >
            <div class="accordion-body">
              <Form 
                ref="incidentForm"
              >
                <div class="row">
                  <div class="col-md-3 col-xs-6 col-xxs-12">
                    <div class="form-group">
                      <label for="IncidentIdFilter">Incident ID</label>
                      <input
                        v-model="filters.incident_id"
                        type="text"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="col-md-3 col-xs-6 col-xxs-12">
                    <div class="form-group">
                      <label for="SiteFilter">Site</label>
                      <Multiselect
                        v-model="filters.site"
                        :options="sites"
                        :multiple="false"
                        :closeOnSelect="true"
                        :clearOnSelect="false"
                        :hideSelected="true"
                        :preserveSearch="true"
                        placeholder="Select Site"
                        label="name"
                        track-by="id"
                      ></Multiselect>
                    </div>
                  </div>
                  <div class="col-md-3 col-xs-6 col-xxs-12">
                    <div class="form-group">
                      <label for="StatusFilter">Status</label>
                      <select v-model="filters.status" class="form-control">
                        <option value="">Select Status</option>
                        <option v-for="status in statuses" :value="status">
                          {{ status }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-3 col-xs-6 col-xxs-12">
                    <div class="form-group">
                      <label for="TypeFilter">Types</label>
                      <Multiselect
                        v-if="types.length > 0"
                        v-model="filters.types"
                        :class="{ 'multi-dark': $store.state.auth.status.dark }"
                        mode="tags"
                        placeholder="Select types"
                        :close-on-select="false"
                        :searchable="true"
                        :create-option="false"
                        :multiple="true"
                        :options="types"
                        label="name"
                        track-by="id"
                        name="types"
                        class="m-b-sm"
                      />
                    </div>
                  </div>
                  <div class="col-md-3 col-xs-6 col-xxs-12">
                    <div class="form-group">
                      <label for="TimeFrameFilter">Time Frame</label>
                      <select v-model="selectedTimeFrame" @change="getIncidentsWithTimeFrame" class="form-control">
                        <option value="this_week">This Week</option>
                        <option value="last_week">Last Week</option>
                        <option value="this_month">This Month</option>
                        <option value="last_month">Last Month</option>
                        <option value="this_quarter">This Quarter</option>
                        <option value="last_quarter">Last Quarter</option>
                        <option value="this_year">This Year</option>
                        <option value="last_year">Last Year</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-3 col-xs-6 col-xxs-12">
                    <div class="form-group">
                      <label for="FaceFilter">Face Filter</label>
                      <Multiselect
                        v-model="selectedPerson"
                        :options="people"
                        :custom-label="customLabel"
                        track-by="person_id"
                        placeholder="Select a Face"
                        :show-labels="false"
                      >
                        <template v-slot:singleLabel="{ option }">
                          <div style="text-align: center;">
                            <FaceFilterCarousel v-if="option.assets.length > 0" :assets="option.assets" />
                          </div>
                        </template>

                        <template v-slot:option="{ option }">
                          <div style="text-align: center;">
                            <FaceFilterCarousel v-if="option.assets.length > 0" :assets="option.assets" />
                            <span v-else>No Image</span>
                          </div>
                        </template>
                      </Multiselect>
                    </div>
                  </div>
                </div>
                <div class="row" v-if="showAdvancedFilters">
                  <hr>
                  <h5>Advanced Date Filter</h5>
                  <div class="row">
                    <div class="col-md-3 col-xs-6 col-xxs-12">
                      <div class="form-group">
                        <label for="StartDateFilter">Start Date</label>
                        <input
                          v-model="filters.start_date"
                          type="date"
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div class="col-md-3 col-xs-6 col-xxs-12">
                      <div class="form-group">
                        <label for="EndDateFilter">End Date</label>
                        <input
                          v-model="filters.end_date"
                          type="date"
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div class="col-md-3 col-xs-6 col-xxs-12">
                      <div class="form-group">
                        <label for="StartTimeFilter">Start Time</label>
                        <input
                          v-model="filters.start_time"
                          type="time"
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div class="col-md-3 col-xs-6 col-xxs-12">
                      <div class="form-group">
                        <label for="EndTimeFilter">End Time</label>
                        <input
                          v-model="filters.end_time"
                          type="time"
                          class="form-control"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row m-t-md" style="justify-content: center;">
                  <div class="col-12" style="width:auto;">
                    <button :disabled="loading" class="btn btn-primary" style="max-width: 150px;" @click="getIncidents(1)">
                      <span v-show="loading" class="spinner-border spinner-border-sm m-r-xxs"></span>
                      <span>{{ $t('events.filters.search') }}</span>
                    </button>
                  </div>
                  
                  <div class="col-12" style="width:auto;">
                    <button type="reset" :disabled="loading" class="btn btn-secondary" style="max-width: 150px;" @click="clearFilters()">
                      <span v-show="loading" class="spinner-border spinner-border-sm m-r-xxs"></span>
                      <span>{{ $t('global.clear_filter') }}</span>
                    </button>
                  </div>

                  <div class="col-12" style="width:auto;">
                    <button type="button" class="btn btn-secondary" style="max-width: 150px;" @click="showAdvancedFilters = !showAdvancedFilters">
                      <span>Advanced</span>
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          </div>

        </div>  
      </div>

    </div>
  </div>

  <br />
  <h1 class="table-heading" style="padding: 0; margin: 0">
    {{ $t("navigation.side.incidents") }}
  </h1>

  <div class="row">
    <Pagination :config="pagination" @search="getIncidents" />
    <div class="col-12">
      <table class="table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Person</th>
            <th>Types</th>
            <th>Description</th>
            <th>Status</th>
            <th>Date of Incident</th>
            <th>Date Created</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-if="incidents.length > 0"
            v-for="incident of incidents"
            :key="'incident-' + incident.id"
          >
            <td style="width: 2%">{{ incident.id }}</td>
            <td style="width: 10%">
              <img
                v-viewer
                v-if="incident.faces[0]?.url"
                :src="incident.faces[0].url"
                class="img-fluid"
                width="150"
                height="auto"
                alt="Person"
                @contextmenu.prevent
              />
            </td>
            <td style="width: 10%">
              {{ incident.types?.join(", ") }}
            </td>
            <td class="incidentDescription">
              {{ incident.description }}
            </td>
            <td style="width: 10%">
              {{ formattedStatus(incident.status) }} 
                <i
                  class="material-icons"
                  style="cursor: pointer"
                @mouseover="showTooltip(incident.id)"
                @mouseleave="hideTooltip"
              >
                help
              </i>
              <p
                v-if="tooltipVisibleId === incident.id"
                class="tooltip-custom"
              >
                {{ statusExplanation(incident.status) }}
              </p>
            </td>
            <td style="width: 12.5%">
              {{ convertDate(incident.happened_at) }}
            </td>
            <td style="width: 12.5%">
              {{ convertDate(incident.created_at) }}
            </td>
            <td style="width: 7%">
              <button
                class="btn btn-sm btn-primary"
                @click="
                  $router.push(
                    `/faces/incidents/${incident.id}?site_id=${incident.site_id}`
                  )
                "
              >
                Manage
              </button>
            </td>
          </tr>
          <tr>
            <td colspan="100%" v-if="loading" class="text-center">
              <div class="spinner-border" role="status">
                <span class="sr-only"></span>
              </div>
            </td>
            <td
              colspan="100%"
              v-if="!loading && incidents.length === 0"
              class="text-center"
            >
              No incidents found.
            </td>
          </tr>
        </tbody>
      </table>
      <Pagination :config="pagination" @search="getIncidents" />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { Multiselect } from "vue-multiselect";
import { Form, Field, ErrorMessage } from "vee-validate";
import Pagination from "../../components/Pagination.vue";
import authHeader from "../../services/auth-header";
import ClientService from "../../services/client.service";
import { DateTime } from "luxon";
import FaceFilterCarousel from "../../components/FaceFilterCarousel.vue";

export default {
  name: "Incidents",
  components: {
    Multiselect,
    Pagination,
    Form,
    Field,
    ErrorMessage,
    FaceFilterCarousel,
  },
  data() {
    return {
      incidents: [],
      types: [],
      loading: false,
      sites: [],
      statuses: ["Pending", "Approved", "Rejected", "Closed"],
      filters: {
        start_date: "",
        end_date: "",
        start_time: "",
        end_time: "",
        incident_id: "",
        status: "",
        site: null,
        types: [],
        person_id: null,
      },
      pagination: {
        page: 1,
        showing: 25,
        maxPage: 1,
        total: 0,
      },
      tooltipVisibleId: null,
      showAdvancedFilters: false,
      selectedTimeFrame: null,
      people: [],
      selectedPerson: null,
      typesToRestore: [],
    };
  },
  computed: {
    statusExplanation() {
      return (status) => {
        switch (status) {
          case "PendingAsset":
            return "The incident is currently being processed before a review.";
          case "Pending":
            return "The incident is under review.";
          case "PendingClosed":
            return "The incident is pending a requested closure review.";
          case "AwaitingEvidence":
            return "The incident is awaiting additional evidence.";
          case "PendingNational":
            return "Due to the severity of this incident, it is being placed under national review.";
          case "ApprovedNational":
            return "The incident has been approved nationally.";
          case "RejectedNational":
            return "The incident has been rejected nationally.";
          case "Closed":
            return "The incident has been closed.";
          case "Rejected":
            return "The incident has been rejected.";
          case "Approved":
            return "The incident has been approved.";
          default:
            return "Unknown status.";
        }
      };
    },
    selectedPersonId() {
      return this.selectedPerson ? this.selectedPerson.person_id : null;
    },
  },
  watch: {
    selectedPersonId(newPersonId) {
      this.filters.person_id = newPersonId;
    },
    people(newPeople) {
      if (this.filters.person_id && newPeople.length > 0) {
        this.selectedPerson = newPeople.find(person => 
          String(person.person_id) === String(this.filters.person_id)
        ) || null;
      }
    },
    types(newTypes) {
      if (this.typesToRestore && this.typesToRestore.length > 0) {
        this.filters.types = newTypes.filter(type => 
          this.typesToRestore.includes(String(type.id))
        ) || [];
        this.typesToRestore = [];
      }
    }
  },
  mounted() {
    this.loadSites();
    this.disableImageContextMenu();
    this.getTypes();
  },
  methods: {
    disableImageContextMenu() {
      document.querySelectorAll("img").forEach((img) => {
        img.addEventListener("contextmenu", (e) => e.preventDefault());
      });
    },
    getIncidents(page = 1) {
      if (this.loading) return;
      this.loading = true;

      if (!this.filters.site?.id) {
        this.$error("Please select a site!");
        this.loading = false;
        return;
      }

      let params = {
        page: page,
        site_id: this.filters.site ? this.filters.site.id : null,
      };

      if (this.filters.start_date) params.start = this.filters.start_date;
      if (this.filters.end_date) params.end = this.filters.end_date;
      if (this.filters.start_time) params.start_time = this.filters.start_time;
      if (this.filters.end_time) params.end_time = this.filters.end_time;
      if (this.filters.incident_id) params.incident_id = this.filters.incident_id;
      if (this.filters.status) params.status = this.filters.status;
      if (this.filters.types.length > 0) {
        params.types = this.filters.types.map((type) => type.id).join(",");
      }
      if (this.filters.person_id) params.person_id = this.filters.person_id;

      this.saveFiltersToSessionStorage();

      axios
        .get(`https://api.varsanpr.com/customer/incident/incidents`, {
          params,
          headers: authHeader(),
        })
        .then((response) => {
          this.incidents = response.data.incidents;
          this.pagination = {
            page: page,
            maxPage:
              response.data.pagination.total > 0
                ? Math.ceil(
                    response.data.pagination.total / this.pagination.showing
                  )
                : 1,
            total: response.data.pagination.total,
            showing: this.pagination.showing,
          };
          this.getFaceFilter()
        })
        .catch((error) => {
          this.$error(error.response.data.message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    saveFiltersToSessionStorage() {
      const filtersToSave = {
        incident_id: this.filters.incident_id || null,
        site_id: this.filters.site ? this.filters.site.id : null,
        status: this.filters.status || null,
        types: this.filters.types && this.filters.types.length > 0 
          ? this.filters.types.map(type => String(type.id)) 
          : [],
        start_date: this.filters.start_date || null,
        end_date: this.filters.end_date || null,
        start_time: this.filters.start_time || null,
        end_time: this.filters.end_time || null,
        person_id: this.filters.person_id || null,
        selectedTimeFrame: this.selectedTimeFrame || null
      };
      sessionStorage.setItem("portalIncidentFilters", JSON.stringify(filtersToSave));
    },
    restoreFiltersFromSessionStorage() {
      try {
        const savedFilters = sessionStorage.getItem("portalIncidentFilters");
        if (!savedFilters) {
          this.getIncidents();
          return;
        }
        
        const filters = JSON.parse(savedFilters);
        
        if (filters.site_id && this.sites.length > 0) {
          this.filters.site = this.sites.find(site => site.id === filters.site_id) || null;
        }
        
        this.filters.incident_id = filters.incident_id || "";
        this.filters.status = filters.status || "";
        
        if (filters.types && filters.types.length > 0) {
          const typeIdsToMatch = filters.types.map(id => String(id));
          
          if (this.types.length > 0) {
            this.filters.types = this.types.filter(type => 
              typeIdsToMatch.includes(String(type.id))
            ) || [];
          } else {
            this.typesToRestore = typeIdsToMatch;
          }
        }
        
        this.filters.start_date = filters.start_date || "";
        this.filters.end_date = filters.end_date || "";
        this.filters.start_time = filters.start_time || "";
        this.filters.end_time = filters.end_time || "";
        this.selectedTimeFrame = filters.selectedTimeFrame || null;
        
        if (filters.person_id) {
          this.filters.person_id = filters.person_id;
        }
        
        this.getIncidents();
      } catch (error) {
        console.error('Error restoring filters from session storage:', error);
        this.getIncidents();
      }
    },
    clearFilters() {
      this.filters = {
        start_date: "",
        end_date: "",
        start_time: "",
        end_time: "",
        incident_id: "",
        status: "",
        site: this.filters.site,
        types: [],
      };
      this.selectedTimeFrame = null;
      this.selectedPerson = null;
      sessionStorage.removeItem("portalIncidentFilters");
      this.getIncidents();
    },
    getTypes() {
      axios
        .get(`https://api.varsanpr.com/customer/incident/incidents/types`, {
          headers: authHeader(),
        })
        .then((response) => {
          this.types = response.data.types;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getIncidentsWithTimeFrame() {
      const now = DateTime.local();
      switch(this.selectedTimeFrame) {
        case 'this_week':
          this.filters.start_date = now.startOf('week').toFormat('yyyy-MM-dd');
          this.filters.end_date = now.endOf('week').toFormat('yyyy-MM-dd');
          break;
        case 'last_week':
          this.filters.start_date = now.minus({ week: 1 }).startOf('week').toFormat('yyyy-MM-dd');
          this.filters.end_date = now.minus({ week: 1 }).endOf('week').toFormat('yyyy-MM-dd');
          break;
        case 'this_month':
          this.filters.start_date = now.startOf('month').toFormat('yyyy-MM-dd');
          this.filters.end_date = now.endOf('month').toFormat('yyyy-MM-dd');
          break;
        case 'last_month':
          this.filters.start_date = now.minus({ month: 1 }).startOf('month').toFormat('yyyy-MM-dd');
          this.filters.end_date = now.minus({ month: 1 }).endOf('month').toFormat('yyyy-MM-dd');
          break;
        case 'this_quarter':
          this.filters.start_date = now.startOf('quarter').toFormat('yyyy-MM-dd');
          this.filters.end_date = now.endOf('quarter').toFormat('yyyy-MM-dd');
          break;
        case 'last_quarter':
          this.filters.start_date = now.minus({ quarter: 1 }).startOf('quarter').toFormat('yyyy-MM-dd');
          this.filters.end_date = now.minus({ quarter: 1 }).endOf('quarter').toFormat('yyyy-MM-dd');
          break;
        case 'this_year':
          this.filters.start_date = now.startOf('year').toFormat('yyyy-MM-dd');
          this.filters.end_date = now.endOf('year').toFormat('yyyy-MM-dd');
          break;
        case 'last_year':
          this.filters.start_date = now.minus({ year: 1 }).startOf('year').toFormat('yyyy-MM-dd');
          this.filters.end_date = now.minus({ year: 1 }).endOf('year').toFormat('yyyy-MM-dd');
          break;
      }
      this.getIncidents();
    },
    getFaceFilter() {
      let params = {
        site_id: this.filters.site ? this.filters.site.id : null,
      };
      axios.get(`https://api.varsanpr.com/customer/incident/incidents/filter/people`, {
        params,
        headers: authHeader(),
      })
      .then((response) => {
        let peopleArray = Object.keys(response.data.people).map(key => {
          return {
            person_id: key,
            ...response.data.people[key]
          }
        })

        peopleArray.sort((a, b) => b.person_id - a.person_id);
        this.people = peopleArray;
      })
      .catch((e) => {
        console.error(e);
      });
    },
    customLabel(person) {
      return `Person ID: ${person.person_id}`;
    },
    async loadSites() {
      const response = await ClientService.getClientSites(
        this.$store.state.auth.user.selectedClient,
        null,
        null,
        5
      );
      this.sites = response.data.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });
      if (this.sites.length > 0) {
        this.filters.site = this.sites[0];
        this.restoreFiltersFromSessionStorage();
      }
    },
    convertDate(dateString) {
      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      };
      const date = new Date(dateString);
      return date.toLocaleDateString("en-UK", options);
    },
    formattedStatus(status) {
      switch (status) {
        case "PendingAsset":
          return "Processing";
        case "Pending":
          return "Pending Review";
        case "PendingClosed":
          return "Pending Close Review";
        case "AwaitingEvidence":
          return "Awaiting Evidence";
        case "PendingNational":
          return "Pending National Review";
        case "ApprovedNational":
          return "Approved National";
        case "RejectedNational":
          return "Rejected National";
        default:
          return status;
      }
    },
    showTooltip(incidentId) {
      this.tooltipVisibleId = incidentId;
    },
    hideTooltip() {
      this.tooltipVisibleId = null;
    },
  },
};
</script>

<style scoped>
.incidentDescription {
  width: 50%;
  white-space: normal;
  word-wrap: break-word;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tooltip-custom {
  position: absolute;
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 3px;
  z-index: 1000;
}
</style>
