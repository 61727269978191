<template>
  <div class="carousel-container">
    <img v-if="currentImage.url" :src="currentImage.url" alt="Face" class="carousel-image" />
    <div v-else class="no-image">No Image Available</div>
  </div>
</template>

<script>
export default {
  props: {
    assets: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      currentIndex: 0,
      intervalId: null,
    }
  },
  computed: {
    currentImage() {
      return this.assets[this.currentIndex] || {};
    },
  },
  mounted() {
    this.startCarousel();
  },
  beforeDestroy() {
    this.stopCarousel();
  },
  methods: {
    startCarousel() {
      this.intervalId = setInterval(() => {
        this.currentIndex = (this.currentIndex + 1) % this.assets.length;
      }, 2000);
    },
    stopCarousel() {
      clearInterval(this.intervalId);
    },
  }
}
</script>

<style scoped>
.carousel-container {
  text-align: center;
}
.carousel-image {
  max-width: 77%;
  max-height: 77%;
}
.no-image {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 77%;
  color: #999;
  font-size: 14px;
}
</style>